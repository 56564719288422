body {
    font-family: 'Lato', sans-serif;
    background-color: #f5f5f5;
}

input {
    border: none;
    width: 52%;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1.5;
    padding: 5px;
    padding-bottom: 6px;
  }
  
  input:focus {
    outline-width: 0;
  }
  
  
button {
    margin-top: .5px;
    background-color:rgb(2, 1, 14);
    padding: 8px 12px;
    margin: 5px;
    border: 2px solid #091a2d;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }